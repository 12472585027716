<template>
    <div class="padding-container bg-red-500 h-48">
        <h1 class="mt-20">Ups no existe nada acá</h1>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>